.frontendContainer {
	display: flex;
	flex-direction: column;
	height: 97%;
	width: 100%;
	max-width: 100%;
	max-height: 97%;
	padding: 0;
}

.header {
	display: flex;
	background-color: #ffffff;
	text-align: center;
	flex-direction: column;
	height: 5%;
	width: auto;
	justify-content: space-between;
	position: relative; /* Ensure the header is positioned relative to handle the logo */
	font-size: 40px;
	color: white;
}

.logo {
	position: absolute;
	top: 10px;
	right: 10px;
	height: 70px; /* Adjust size as needed */
	float: right;
	z-index: 1;
}

.column {
	top: 0px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	height: 97%;
	padding: 0;
	max-width: 100%;
	max-height: 97%;
	color: white;
}

.top-datafield {
	background-color: #2bb0d4;
	display: flex;
	flex-direction: row;
	font-size: 15px;
	justify-content: space-between;
	color: white;
}

.swipe-container {
	width: 100%;
	height: 100%; /* Ensure full height usage */
	max-width: 100%;
	max-height: 100%;
}

.content-item {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%; /* Ensure content items take full height */
}

iframe {
	border: none;
	height: 100%;
	object-fit: contain;
	max-width: 100%;
	max-height: 100%;
}

.image-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	overflow: hidden;
	max-width: 100%;
	max-height: 100%;
	overflow: hidden;
}

.image-container img {
	object-fit: contain;
	max-width: 100%;
	max-height: 100%;
}
