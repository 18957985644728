*,
::before,
::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

label {
	font-size: 1.2rem;
	color: #656262;
}

html,
body,
#root {
	margin: 0;
	padding: 0;
	height: 100%;
}

body {
	background: #000000;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.root {
	width: 100%;
}

.form_container {
	background-color: #fff;
	padding: 2rem 3rem;
	border-radius: 0.5rem;
	width: 30%;
	max-width: 30%;
	box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
	align-items: center;
	position: absolute;
	top: 35%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.form_container > h2 {
	margin-block: 1rem;
	padding-block: 0.6rem;
	color: rgba(0, 212, 255, 1);
}

.form_container > form {
	display: flex;
	flex-direction: column;
	gap: 1.4rem;
}

.form_container div {
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
}

.form_container input {
	border: none;
	padding: 0.5rem;
	border-bottom: 1px solid gray;
	font-size: 1.1rem;
	outline: none;
}

.form_container input::placeholder {
	font-size: 0.9rem;
	font-style: italic;
}

.form_container button {
	background-color: rgba(0, 212, 255, 1);
	color: #fff;
	border: none;
	padding: 0.6rem;
	font-size: 1rem;
	cursor: pointer;
	border-radius: 0.3rem;
}

span a {
	text-decoration: none;
	color: rgba(0, 212, 255, 1);
}

.role {
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
}
