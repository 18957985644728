.library_page {
	height: 3%;
	width: 100%;
	background: #000;
	color: white;
	display: flex;
	text-transform: uppercase;
	font-size: 1.5rem;
	gap: 1rem;
}

.library_page span {
	color: orange;
}

.library_page button {
	background-color: rgb(27, 73, 83);
	color: #fff;
	cursor: pointer;
	padding: 0.05rem 0.6rem;
	font-size: 1rem;
	border-radius: 0.3rem;
	transition: ease-in 0.3s;
	border-color: white;
}

.library_page button:hover {
	background-color: orangered;
}

@media only screen and (max-width: 1200px) {
	.library_page {
		font-size: 1.5rem;
	}
	.library_page button {
		padding: 0.05rem 0.6rem;
		font-size: 1rem;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.library_page_settings {
	height: 97vh;
	width: 100%;
	background: #282626;
	color: white;
}
