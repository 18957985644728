.swipe-container {
	width: 100%;
	height: 100%; /* Ensure full height usage */
	max-width: 100%;
	max-height: 100%;
	overflow: hidden; /* Prevent overflow */
}

.slick-slider {
	width: 100%;
	height: 100%; /* Ensure the slider takes the full height */
}

.slick-list {
	width: 100%;
	height: 100%;
}

.slick-track {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.slick-slide {
	display: flex !important; /* Override any inline styles */
	align-items: center;
	justify-content: center;
	height: 100%;
}

.slick-slide > div {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.content-item {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%; /* Ensure content items take full height */
	overflow: hidden;
}
