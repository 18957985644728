.App {
	text-align: center;
}

.popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

.popup-inner {
	background: white;
	padding: 20px;
	border-radius: 8px;
	width: 300px;
	max-width: 80%;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

form label {
	display: block;
	margin-bottom: 10px;
}

form input {
	width: 100%;
	padding: 8px;
	margin-top: 4px;
	margin-bottom: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

form button {
	padding: 10px 15px;
	margin-right: 10px;
	border: none;
	background-color: #007bff;
	color: white;
	border-radius: 4px;
	cursor: pointer;
}

form button[type='button'] {
	background-color: #dc3545;
}
