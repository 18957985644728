.image-container {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	object-fit: contain;
}

.image-container img {
	object-fit: contain; /* Ensures the image fits within the container while maintaining aspect ratio */
}
